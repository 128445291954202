import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import useModalContext from '../useModalContext';

import StyledImg from '../../styled/elements/StyledImg';
import StyledModal from '../../styled/layouts/StyledModal';
import StyledModalCloseButton from '../../styled/elements/StyledModalCloseButton';

import cross from '../../../images/cross.svg';

const BaseModal = ({ children }) => {
  const closeButtonRef = useRef();

  const { hideModal } = useModalContext();

  useEffect(
    () => (closeButtonRef.current ? closeButtonRef.current.focus() : null),
    [closeButtonRef],
  );

  const handleCloseClick = useCallback(
    () => hideModal(),
    [hideModal, closeButtonRef],
  );

  return (
    <StyledModal>
      <StyledModalCloseButton
        ref={closeButtonRef}
        onClick={handleCloseClick}
      >
        <StyledImg src={cross} alt="Stäng" />
      </StyledModalCloseButton>
      {children}
    </StyledModal>
  );
};

BaseModal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseModal;
