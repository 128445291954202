import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Header from './Header';
import Footer from './Footer';

import GlobalStyle from '../../styled/GlobalStyle';
import StyledLayout from '../../styled/layouts/StyledLayout';
import StyledFlexBox from '../../styled/layouts/StyledFlexBox';

import ModalProvider from '../../modals/ModalProvider';
import ModalManager from '../../modals/ModalManager';

const Layout = ({ children }) => (
  <ModalProvider>
    <StyledLayout>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyle />
      <Header />
      <StyledFlexBox
        flexDirection="column"
        alignItems="stretch"
        flexGrow="1"
      >
        {children}
      </StyledFlexBox>
      <Footer />
    </StyledLayout>
    <ModalManager />
  </ModalProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
