import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { ModalContext } from './useModalContext';
import templates from './templates';

const ModalProvider = ({ children }) => {
  const [display, setDisplay] = useState(false);
  const [template, setTemplate] = useState('');
  const [options, setOptions] = useState({});

  const showModal = useCallback(
    (value, modalParams) => {
      setDisplay(true);
      setTemplate(value);
      setOptions(modalParams);
    },
    [setDisplay, setTemplate, setOptions],
  );

  const hideModal = useCallback(
    () => setDisplay(false),
    [setDisplay, setTemplate],
  );

  const initialContext = {
    display,
    template,
    templates,
    showModal,
    hideModal,
    options,
  };

  return (
    <ModalContext.Provider value={initialContext}>
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalProvider;
