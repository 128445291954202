import { createGlobalStyle } from 'styled-components';

import style from '../../constants/style';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    font-family: 'Nunito Sans', sans-serif;
  }

  html {
    background: ${style.colours.primary};
  }

  body {
    background: ${style.colours.light};
  }
`;

export default GlobalStyle;
