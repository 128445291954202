import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import numberFormat from '../../../utils/numberFormat';

import StyledWrappedColumn from '../../styled/layouts/StyledWrappedColumn';
import StyledFlexBox from '../../styled/layouts/StyledFlexBox';
import StyledH4 from '../../styled/elements/StyledH4';
import StyledTable from '../../styled/elements/StyledTable';

import BaseModal from './BaseModal';
import useModalContext from '../useModalContext';

const StyledTableRow = styled.tr`
  height: 2.5rem;
  border-bottom: 1px solid #E2E2E2;
  &:last-child { border-bottom: 0; }
  &:first-child {  border-bottom: 1px solid #E2E2E2; }
`;

const StyledTableCellHeader = styled.th`
  font-weight: bold;
  text-align: left;
  padding: 0 1rem;
  width: (100% - 2rem);
  text-transform: capitalize;
`;

const StyledTableCell = styled.td`
  text-align: left;
  padding: 0 1rem;
  width: (100% - 2rem);
  text-transform: capitalize;
`;

const CategorisationDetails = ({ detail }) => {
  const [showDetails, setShowDetails] = useState(false);
  const { label, transactions, sum } = detail;
  return (
    <>
      <StyledTableRow onClick={() => setShowDetails(!showDetails)} key={`${label}-summary`}>
        <StyledTableCell>{label}</StyledTableCell>
        <StyledTableCell>{numberFormat(sum, 0)}</StyledTableCell>
        <StyledTableCell>{transactions.length}</StyledTableCell>
      </StyledTableRow>
      {showDetails && (
        <StyledTableRow onClick={() => setShowDetails(!showDetails)} key={`${label}-transactions`}>
          <StyledTableCell colSpan="3">
            <StyledTable>
              <thead>
                <StyledTableRow>
                  <StyledTableCellHeader>Datum</StyledTableCellHeader>
                  <StyledTableCellHeader>Belopp</StyledTableCellHeader>
                  <StyledTableCellHeader>Kontonummer</StyledTableCellHeader>
                </StyledTableRow>
                {}
              </thead>
              <tbody>
                {transactions.map((transaction) => (
                  <StyledTableRow key={`${label}-${JSON.stringify(transaction)}`}>
                    <StyledTableCell>{moment(transaction.date).format('YYYY/MM/DD')}</StyledTableCell>
                    <StyledTableCell>{numberFormat(transaction.amount, 0)}</StyledTableCell>
                    <StyledTableCell>{transaction.accountNumber}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </tbody>
            </StyledTable>
          </StyledTableCell>
        </StyledTableRow>
      )}
    </>
  );
};

CategorisationDetails.propTypes = {
  detail: PropTypes.shape({
    label: PropTypes.string.isRequired,
    sum: PropTypes.number.isRequired,
    transactions: PropTypes.arrayOf({
      date: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      accountNumber: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const CategorisationModal = () => {
  const { options: data } = useModalContext();

  return (
    <BaseModal>
      <StyledWrappedColumn>
        <StyledFlexBox flexDirection="column">
          <StyledH4 textAlign="center">Positiva transaktioner</StyledH4>
          <StyledTable>
            <thead>
              <StyledTableRow>
                <StyledTableCellHeader>Beskrivning</StyledTableCellHeader>
                <StyledTableCellHeader>Summa</StyledTableCellHeader>
                <StyledTableCellHeader>Antal</StyledTableCellHeader>
              </StyledTableRow>
            </thead>
            <tbody>
              {data.posDet.map((detail) => (
                <CategorisationDetails detail={detail} key={detail.label} />
              ))}
            </tbody>
          </StyledTable>
        </StyledFlexBox>
        <StyledFlexBox flexDirection="column">
          <StyledH4 textAlign="center">Negativa transaktioner</StyledH4>
          <StyledTable>
            <thead>
              <StyledTableRow>
                <StyledTableCellHeader>Beskrivning</StyledTableCellHeader>
                <StyledTableCellHeader>Summa</StyledTableCellHeader>
                <StyledTableCellHeader>Antal</StyledTableCellHeader>
              </StyledTableRow>
            </thead>
            <tbody>
              {data.negDet.map((detail) => (
                <CategorisationDetails detail={detail} key={detail.label} />
              ))}
            </tbody>
          </StyledTable>
        </StyledFlexBox>
      </StyledWrappedColumn>
    </BaseModal>
  );
};

export default CategorisationModal;
