import styled from 'styled-components';
import { rgba } from 'polished';

import style from '../../../constants/style';

const StyledModalContainer = styled.div`
  z-index: ${style.zLevels.priority};
  position: fixed;
  top: ${style.spaces.none};
  left: ${style.spaces.none};
  bottom: ${style.spaces.none};
  right: ${style.spaces.none};

  display: block;
  background-color: ${rgba(style.colours.dark, 0.4)};
`;

export default StyledModalContainer;
