import React, { useRef, useCallback } from 'react';

import StyledModalContainer from '../styled/layouts/StyledModalContainer';

import { templatesMap } from './templates';

import useModalContext from './useModalContext';

const ModalManager = () => {
  const {
    display,
    template,
    hideModal,
  } = useModalContext();

  const wrapperRef = useRef();

  const handleEscape = useCallback(
    (e) => (e.keyCode === 27 ? hideModal() : null),
    [hideModal],
  );

  const handleWrapperClick = useCallback(
    (e) => (e.target === wrapperRef.current ? hideModal() : null),
    [hideModal, wrapperRef],
  );

  if (!display) return null;

  return (
    <StyledModalContainer
      ref={wrapperRef}
      onClick={handleWrapperClick}
      onKeyDown={handleEscape}
    >
      {templatesMap[template]}
    </StyledModalContainer>
  );
};

export default ModalManager;
