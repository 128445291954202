import React from 'react';
import { Link } from 'gatsby';

import StyledParagraph from '../../styled/elements/StyledParagraph';
import StyledH2 from '../../styled/elements/StyledH2';

import StyledFlexBox from '../../styled/layouts/StyledFlexBox';
import StyledWrappedColumn from '../../styled/layouts/StyledWrappedColumn';
import StyledFooter, { StyledFooterContent } from '../../styled/layouts/StyledFooter';
import StyledAnchor from '../../styled/elements/StyledAnchor';

import iconomicInfo from '../../../constants/iconomicInfo';

const currentYear = () => new Date().getFullYear();

const Footer = () => (
  <StyledFooter>
    <StyledFooterContent>
      <StyledH2>Kontakt</StyledH2>
      <StyledWrappedColumn
        inBetweenMargin="tiny"
        leftProportion={0.6}
      >
        <StyledFlexBox flexDirection="column">
          <StyledParagraph>{iconomicInfo.EMAIL}</StyledParagraph>
          <StyledParagraph>{iconomicInfo.PHONE}</StyledParagraph>
          <StyledParagraph>
            {iconomicInfo.COMPANY_NAME},<br />
            {iconomicInfo.ADDRESS}
          </StyledParagraph>
          <StyledParagraph>
            Besöksadress:<br />
            {iconomicInfo.STREET}
          </StyledParagraph>
        </StyledFlexBox>
        <StyledFlexBox flexDirection="column">
          <StyledParagraph><StyledAnchor as={Link} to="/personuppgiftspolicy">Personuppgiftspolicy</StyledAnchor></StyledParagraph>
          <StyledParagraph><StyledAnchor as={Link} to="/cookies">Om Cookies</StyledAnchor></StyledParagraph>
        </StyledFlexBox>
      </StyledWrappedColumn>
      <StyledFlexBox marginTop="big" justifyContent="center">
        <StyledParagraph
          textAlign="center"
          fontSize="tiny"
          fontWeight="lighter"
        >
          Copyright &copy;{currentYear()}&nbsp;Iconomic. Alla rättigheter förbehålls.
        </StyledParagraph>
      </StyledFlexBox>
    </StyledFooterContent>
  </StyledFooter>
);

export default Footer;
