import React from 'react';

import BaseModal from './BaseModal';

import StyledH2 from '../../styled/elements/StyledH2';
import StyledParagraph from '../../styled/elements/StyledParagraph';
import StyledFlexBox from '../../styled/layouts/StyledFlexBox';
import StyledImg from '../../styled/elements/StyledImg';

import approved from '../../../images/approved.svg';

const ThankYouModal = () => (
  <BaseModal>
    <StyledH2 textColour="primary" textAlign="center">Tack för din kontakt</StyledH2>
    <StyledImg src={approved} alt="Allt gick bra" />
    <StyledFlexBox maxWidth="24rem" marginTop="regular" alignSelf="center" flexDirection="column">
      <StyledParagraph textColour="primary" textAlign="center" fontSize="small">Vi ser fram emot att prata med dig och kommer att återkomma inom en veckodag.</StyledParagraph>
    </StyledFlexBox>
  </BaseModal>
);

export default ThankYouModal;
