import styled from 'styled-components';

import style from '../../../constants/style';

const SMALL_SPACES = style.spaces.small;
const MEDIUM_SPACES = style.spaces.big;
const LARGE_SPACES = style.spaces.huge;

const MOBILE_HORIZONTAL_SPACES_SUM = `((${SMALL_SPACES} * 2) + ${SMALL_SPACES} + ${LARGE_SPACES})`;
const MOBILE_VERTICAL_SPACES_SUM = `((${SMALL_SPACES} * 2) + ${SMALL_SPACES} + ${LARGE_SPACES})`;
const TABLET_VERTICAL_SPACES_SUM = `((${LARGE_SPACES} * 2) + ${MEDIUM_SPACES} + ${LARGE_SPACES} )`;

const StyledModal = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  width: calc(100% - ${MOBILE_HORIZONTAL_SPACES_SUM});
  max-height: calc(100% - ${MOBILE_VERTICAL_SPACES_SUM});

  margin: ${SMALL_SPACES};
  padding: ${SMALL_SPACES};
  padding-top: ${LARGE_SPACES};

  background-color: ${style.colours.light};
  border-radius: 24px;

  ${style.media.tablet`
    max-width: 40rem;
    max-height: calc(100% - ${TABLET_VERTICAL_SPACES_SUM});

    margin: ${LARGE_SPACES} auto;
    padding: ${MEDIUM_SPACES};
    padding-top: ${LARGE_SPACES};
  `}
`;

export default StyledModal;
