import React from 'react';
import { Link } from 'gatsby';

import StyledHeader, { StyledHeaderContent } from '../../styled/layouts/StyledHeader';

import StyledImg from '../../styled/elements/StyledImg';

import logo from '../../../images/logo-negative.svg';

const Header = () => (
  <StyledHeader>
    <StyledHeaderContent>
      <Link to="/">
        <StyledImg src={logo} alt="Iconomic" />
      </Link>
    </StyledHeaderContent>
  </StyledHeader>
);

export default Header;
