import React from 'react';

import BaseModal from './BaseModal';

import StyledH2 from '../../styled/elements/StyledH2';
import StyledParagraph from '../../styled/elements/StyledParagraph';
import StyledFlexBox from '../../styled/layouts/StyledFlexBox';
import StyledImg from '../../styled/elements/StyledImg';

import alert from '../../../images/alert.svg';

const ErrorModal = () => (
  <BaseModal>
    <StyledH2 textColour="error" textAlign="center">Något gick fel.. </StyledH2>
    <StyledImg src={alert} alt="Något gick fel" />
    <StyledFlexBox maxWidth="24rem" marginTop="regular" alignSelf="center" flexDirection="column">
      <StyledParagraph textColour="primary" textAlign="center" fontSize="small">Försök gärna igen.</StyledParagraph>
    </StyledFlexBox>
  </BaseModal>
);

export default ErrorModal;
