import styled from 'styled-components';

import style from '../../../constants/style';

const StyledPage = styled.div`
  min-height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  font-size: ${style.fontSizes.regular};
`;

export default StyledPage;
