import React from 'react';

import HowCalculatorModal from './HowCalculatorModal';
import ThankYouModal from './ThankYouModal';
import ErrorModal from './ErrorModal';
import LoadingModal from './LoadingModal';
import CategorisationModal from './CategorisationModal';

const templates = {
  HOW_CALCULATOR: 'HOW_CALCULATOR',
  THANK_YOU: 'THANK_YOU',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  CATEGORISATION: 'CATEGORISATION',
};

export const templatesMap = {
  [templates.HOW_CALCULATOR]: <HowCalculatorModal />,
  [templates.THANK_YOU]: <ThankYouModal />,
  [templates.ERROR]: <ErrorModal />,
  [templates.LOADING]: <LoadingModal />,
  [templates.HOW_CALCULATOR]: <CategorisationModal />,
  [templates.CATEGORISATION]: <CategorisationModal />,
};

export default templates;
