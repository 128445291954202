import styled from 'styled-components';

import style from '../../../constants/style';

const StyledModalCloseButton = styled.button`
  position: absolute;
  top: ${style.spaces.small};
  right: ${style.spaces.small};

  border: none;
  padding: 0;

  cursor: pointer;

  background: transparent;

`;

export default StyledModalCloseButton;
