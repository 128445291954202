import { createContext, useContext } from 'react';

const initialContext = {
  display: false,
  template: '',
  templates: {},
  displayModal: () => { },
  hideModal: () => { },
};

export const ModalContext = createContext(initialContext);

const useModalContext = () => useContext(ModalContext);

export default useModalContext;
