import React, { useCallback } from 'react';

import BaseModal from './BaseModal';

import useModalContext from '../useModalContext';

import StyledH3 from '../../styled/elements/StyledH3';
import StyledParagraph from '../../styled/elements/StyledParagraph';
import StyledButton from '../../styled/elements/StyledButton';
import StyledFlexBox from '../../styled/layouts/StyledFlexBox';

const HowCalculatorModal = () => {
  const { hideModal } = useModalContext();

  const hideModalClick = useCallback(
    (e) => {
      e.preventDefault();
      hideModal();
    },
    [hideModal],
  );

  return (
    <BaseModal>
      <StyledH3 textAlign="center">Så här har vi räknat</StyledH3>
      <StyledParagraph fontWeight="light">Studie från PWC visar att 30% av medarbetarna är distraherade av sin privatekonomi på arbetet och 46% av dessa spenderar minst 3h arbetstid per vecka på att hantera sin ekonomi.</StyledParagraph>
      <StyledParagraph fontWeight="light">Utifrån detta har vi räknat ut totalkostnaden för arbetsgivaren baserat på antal anställda. Detta har räknas med en genomsnittlig timkostnad på 200 kr per anställd och utgått från 46 närvarande veckor per år.</StyledParagraph>
      <StyledFlexBox justifyContent="center" marginTop="big">
        <StyledButton onClick={hideModalClick}>Stänga</StyledButton>
      </StyledFlexBox>
    </BaseModal>
  );
};

export default HowCalculatorModal;
