import styled from 'styled-components';

import StyledParagraph from '../elements/StyledParagraph';
import StyledAnchor from '../elements/StyledAnchor';
import StyledWrappedColumn from './StyledWrappedColumn';
import StyledFlexBox from './StyledFlexBox';

import style from '../../../constants/style';
import collapseMarginMixin from '../../../mixins/collapseMarginMixin';

export const StyledFooterContent = styled('div')`
  display: flex;
  flex-direction: column;

  max-width: 60rem;
  width: 100%;
  margin: auto;
  padding: 3rem 1.5rem 2rem;

  ${StyledParagraph} {
    margin: ${style.spaces.tiny} 0 0;
    ${collapseMarginMixin}
  }

  ${StyledAnchor} {
    color: ${style.colours.secondary};
    font-weight: ${style.fontWeights.bold};
  }

  ${StyledWrappedColumn} > ${StyledFlexBox} {
    padding-left: ${style.spaces.tiny};
  }

  ${style.media.tablet`
    padding: 4rem 6rem 2rem;

    ${StyledWrappedColumn} > ${StyledFlexBox} {
      padding-left: ${style.spaces.small};
    }
  `}

  ${style.media.laptop`
    ${StyledWrappedColumn} > ${StyledFlexBox} {
      padding-left: ${style.spaces.big};
    }
  `}
`;

const StyledFooter = styled('footer')`
  display: flex;
  background: ${style.colours.primary};
  color: ${style.colours.light};
`;

export default StyledFooter;
