import styled from 'styled-components';

import StyledImg from '../elements/StyledImg';
import style from '../../../constants/style';

export const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;

  height: 100%;
  width: 100%;
  max-width: 70rem;
  margin: auto;
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 3rem;
  padding: 0 1rem;

  background: ${style.colours.primary};

  ${StyledImg} {
    height: 1rem;
  }

  ${style.media.tablet`
    padding: 0 2rem;
  `}

  ${style.media.laptop`
    height: 4.5rem;

    ${StyledImg} {
      height: 1.5rem;
    }
  `}
`;

export default StyledHeader;
