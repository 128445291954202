import React from 'react';

import BaseModal from './BaseModal';

import StyledFlexBox from '../../styled/layouts/StyledFlexBox';
import StyledLoader from '../../styled/elements/StyledLoader';

const LoadingModal = () => (
  <BaseModal>
    <StyledFlexBox
      marginTop="huge"
      marginBottom="huge"
      alignSelf="center"
    >
      <StyledLoader />
    </StyledFlexBox>
  </BaseModal>
);

export default LoadingModal;
